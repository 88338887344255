body{
  background-color: rgb(239, 239, 239) !important;
  font-family: "Beltram-Regular", sans-serif !important;
  /* overflow-x: hidden; */
}
.logo_IMG{
  padding: 0%;
  margin-top: 5px;
}
.contact-main-div{
  background-color: white;
  box-shadow: 1px 1px 5px gray;
  margin-top: 8px;
  display: flex;
}
.contact-main-div img {
  padding: 2%;
}
.chatbot{
  cursor: pointer;
  width: 200px;
  position: fixed;
  bottom: 30px;
  display: flex;
  align-items: center !important;
  text-align: center;
}
.chat-text{
  /* animation: typing 5s steps(40, end) infinite; */
}
.product_main{
  display: flex;
  flex-wrap: wrap;
}
/* For screens larger than mobile */
.hero-section {
  background-image: url('/public/Product_IMG/Ev_Bulk_scooters.png');
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  margin-top: -20px;
}
.description-main{
  display: flex;
  justify-content: space-between;
}
.description-sub1{
  width: 59%;
}
.mobile_footer{
  display: flex;
  align-items: center !important;
}
.mobile_footer a{
  color: white !important;
 text-decoration: none !important;
}


@media only screen and (max-width: 600px) {
  .contact-main-div{
    background-color: white;
    box-shadow: 1px 1px 5px gray;
    margin-top: 8px;
    display: flex;
    flex-direction: column ;
  }
  .hero-section {
    background-image: url('/public/Product_IMG/mobile_view.png');
    height: 700px; /* Adjust height if needed for mobile */
  }
  .contact-main-div img {
   width: 97%;    
  }
  .chatbot{
    cursor: pointer;
    width: 300px;
    position: fixed;
    bottom: 30px;
    display: flex;
    align-items: center !important;
    text-align: center;
  }
}